/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: var(--secondary) var(--primary);
  }
  
  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    height: 10px;
    width: 10px;
  }
  
  
  *::-webkit-scrollbar-track {
    background: var(--primary);
    border-radius: 5px;
    height: 30px;
    width: 30px;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: var(--secondary);
    border-radius: 14px;
    border: 4px solid var(--primary);
    
  }


  :root {
    --primary: white;
    --secondary: black;
  }

  .scrolling-auto { -webkit-overflow-scrolling: auto; }